import {mapMutations} from "vuex";
import About from "@/modules/home/section/components/about/index.vue";

export default {
  name: "promo",
  components:{
    About,
  },
  data(){
    return{
      about: false,
    }
  },
  created() {
  },
  computed:{
  },
  methods:{
    ...mapMutations({
    }),
    changeAbout(){
      this.about = !this.about
    }
  }
}