import {mapGetters, mapMutations} from "vuex";

//sections
import Info from "@/modules/game/components/info/index.vue";
import {tileData} from "@/modules/game/data";
export default {
  name: "controls",
  components: {
    Info,
  },
  props: {
    array: {
      type: Array,
      default: ()=> []
    },
    steps: {
      type: Number,
      default: ()=> 1
    },
  },
  watch: {
    time: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.time < 1000 ? this.end = true : ''
          if (this.end === true) {
            this.changePause()
            this.$emit('change-loose', {
              match: this.countMatched,
              all: this.array.length / 2
            });
            this.minutes = 0
            this.seconds = 0
          }
        }
      }
    },
    pause: {
      immediate: true,
      handler(newVal) {
        if (this.pause === false) {
          this.timer()
        }
      }
    },
    actionPause: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.actionPause === true) {
          this.changePause()
        } else {
          this.pause = false
          this.$emit('change-pause', {
            status: this.pause,
          });
        }
      }
    },
    changeChunk: {
      immediate: true,
      handler(newVal) {
        if (this.changeChunk === true) {
          setTimeout(()=>{
            this.$emit('change-chunk');
          },500)
        }
      }
    },
    win: {
      immediate: true,
      handler(newVal) {
        if (this.win === true) {
          this.$emit('change-win', {
            match: this.countMatched,
            all: this.array.length / 2,
            seconds: Math.floor(((270000 - this.time) / 1000) % 60),
            minutes: Math.floor(((270000 - this.time) / 1000 / 60) % 60),
          });
        }
      }
    },
  },
  data() {
    return {
      pause: false,
      time: 270000,
      currentTime: null,
      seconds: '30',
      minutes: '04',
      end: false,
      info: false,
      changeChunk: false,
      currentStep: 1,
      infoType: false,
    }
  },
  created() {
    if(!localStorage.getItem('played')){
      localStorage.setItem('played', 'true')
      this.changeInfo(true)
    }
  },
  computed: {
    ...mapGetters({
      actionPause: 'system/actionPause'
    }),
    countTimeBar() {
      return ((100 / 270000 * this.time))
    },
    countMatched(){
     return this.array.filter(i=>i.matched===true).length / 2
    },
    win(){
      if(this.countMatched === this.array.length / 2){
        this.currentStep++
        if(this.steps >= this.currentStep){
          this.changeChunk = true
          return false
        } else {
          this.changeChunk = false
          return true
        }
      } else {
        this.changeChunk = false
        return false
      }
    }
  },
  mounted() {
    let that = this
    window.onkeydown = function( event ) {
      if ( event.keyCode == 27 ) {
        //escape
        that.$router.push({name: 'main'}).catch(()=>{console.log()})
      }
      if ( event.keyCode == 80 ) {
        //pause
        if(that.actionPause){
          that.pause = false
          that.setActionPause(false)
          that.$emit('change-pause', {
            status: that.pause,
          });
        } else {
          that.changePause()
        }
      }
      if ( event.keyCode == 73 ) {
        //info
        that.changeInfo(false)
      }
    }


  },
  methods: {
    ...mapMutations({
      setActionPause: 'system/CHANGE_ACTION_PAUSE'
    }),
    changeInfo(status){
      this.infoType = status
      this.info = !this.info
      if(!this.actionPause){
        this.info === false ? this.pause = false : this.pause = true
        this.$emit('change-pause', {
          status: this.pause,
        });
      }
    },
    changeActionPause(){
      this.pause = false
      this.setActionPause(false)
      this.$emit('change-pause', {
        status: this.pause,
      });
    },
    changePause() {
      if(!this.info){
        this.pause = !this.pause
        this.$emit('change-pause', {
          status: this.pause,
        });
      }
    },
    timer() {
      if (!this.pause) {
        if(!this.end && !this.win){
          this.time = this.time - 1000
          this.seconds = Math.floor((this.time / 1000) % 60);
          this.minutes = Math.floor((this.time / 1000 / 60) % 60);
          setTimeout(this.timer, 1000);
        }
      }
    },
    reload(){
      location.reload()
    }
  }
}