import {$http} from '@/utils/https'
import {
  SET_PRODUCT,
  CHANGE_PRODUCT_LOADING,
  RESET_PRODUCT
} from '../mutation-types';

import {
  GET_PRODUCT,
} from '../action-types';


const state = {
  product: null,
  productLoading: false,

};

const getters = {
  product: state => state.product,
  productLoading: state => state.productLoading,
};

const actions = {
  [GET_PRODUCT]: async ({commit}, payload) => {
    commit(CHANGE_PRODUCT_LOADING, true);
    commit(RESET_PRODUCT);
    try {
      const response = await $http.get(`v1/product/${payload.slug}`);
      commit(SET_PRODUCT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PRODUCT_LOADING, false);
    }
  },
};

const mutations = {
  [SET_PRODUCT](state, obj) {
    state.product = obj;
    state.product.slider.data.unshift({
      id: Math.round(Math.random() * 100),
      link: state.product.image
    })
  },
  [RESET_PRODUCT](state) {
    state.product = null;
  },
  [CHANGE_PRODUCT_LOADING](state, status) {
    state.productLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
