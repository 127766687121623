import {mapGetters, mapMutations} from "vuex";

//sections
import Tile from "@/modules/game/components/tile/index.vue";
import Looser from "@/modules/game/components/looser/index.vue";
import Winner from "@/modules/game/components/winner/index.vue";
import Prize from "@/modules/game/components/prize/index.vue";
import Action from "@/modules/game/components/action/index.vue";

export default {
  name: "playground",
  props: {
    tiles: {
      type: Array,
      default: ()=> []
    },
    pause: {
      type: Boolean,
      default: ()=> true
    },
    loose: {
      type: Object,
      default: ()=> null
    },
    win: {
      type: Object,
      default: ()=> null
    },
  },
  components:{
    Tile,
    Looser,
    Winner,
    Prize,
    Action,
  },
  data(){
    return{
      clickEnable: true,
      prize: false,
      action: null,
      actionColor: null,
    }
  },
  watch: {
    actionPause: {
      immediate: true,
      handler(newVal) {
        if (this.actionPause === false) {
          this.actionColor = null
          this.action = null
        }
      }
    },
  },
  created() {
  },
  computed:{
    ...mapGetters({
      actionPause: 'system/actionPause'
    }),
  },

  methods:{
    ...mapMutations({
      setActionPause: 'system/CHANGE_ACTION_PAUSE'
    }),
    checkTiles({data}){
      if (this.clickEnable) {
        let currentItems = this.tiles.filter(i=>i.id === data.id && i.open && i.uniqId !== data.uniqId)
        if (currentItems.length === 1) {
          data.matched = true
          currentItems[0].matched = true;
          data.open = true;
          if(data.action){
            setTimeout(()=>{
              this.action = data.actionData
              this.actionColor = data.actionData.color
              this.setActionPause(true)
            },600)
          }
        } else {
          data.open = !data.open;
          let second = this.tiles.find(i=>i.open && !i.matched && i.uniqId !== data.uniqId)
          if (second) {
            this.clickEnable = false
            setTimeout(()=>{
              second.open = false;
              data.open = false;
              this.clickEnable = true
            }, 800)
          }
        }
      }
    },
    changePrize(){
      this.prize = !this.prize
    }
  }
}