export const tileData = [
  {
    id: 1,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-10.svg',
    title: '',
    action: true,
    actionData: {
      color: 'cyan',
      type: false,
      title: '10%',
      description: 'Якщо хтось з родичів забирає у стареньких пенсію, сусідам варто повідомити про це соцслужби або правоохоронні органи, бо це — прояв економічного насильства. Утім лише 10-20% опитаних розпізнають його у такій ситуації.',
    },
    open: false,
    matched: false,
  },
  {
    id: 2,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-20.svg',
    title: '',
    action: true,
    actionData: {
      color: 'yellow',
      type: false,
      title: '20%',
      description: 'Якщо чоловік б’є дружину, сусіди мають сказати йому, що така поведінка неприпустима. 20–30% опитаних стверджують, що вчинять саме так.',
    },
    open: false,
    matched: false,
  },
  {
    id: 3,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-30.svg',
    title: '',
    action: true,
    actionData: {
      color: 'red',
      type: false,
      title: '30%',
      description: 'Якщо жінка по сусідству зазнає домашнього насильства, їй необхідно допомогти — мобілізувати сусідів та викликати поліцію і спробувати зупинити кривдника. 20–30% людей стверджують, що зроблять саме так.',
    },
    open: false,
    matched: false,
  },
  {
    id: 4,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-38.svg',
    title: '',
    action: true,
    actionData: {
      image: 'https://16days2022.rozirvykolo.org/static/icons/image-plate.svg',
      color: 'gray',
      type: true,
      title: '38%',
      description: 'Коли партнер чи партнерка постійно надзвонюють, перепитують коханих «де ти, з ким ти?» і всіляко контролюють — це психологічне насильство. У середньому, кожен третій опитаний (31–38%) його розрізняє.',
    },
    open: false,
    matched: false,
  },
  {
    id: 5,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-40.svg',
    title: '',
    action: true,
    actionData: {
      color: 'yellow',
      type: false,
      title: '40%',
      description: 'Служби підтримки постраждалих у громадах можуть надати якісну допомогу. Про це знає кожен третій опитаний (30–40%).',
    },
    open: false,
    matched: false,
  },
  {
    id: 6,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-50.svg',
    title: '',
    action: true,
    actionData: {
      color: 'light-orange',
      type: false,
      title: '50%',
      description: 'Чоловік, який познайомився з жінкою на вечірці та зайнявся з нею сексом без її згоди, вчинив сексуальне насильство. І понад 50% опитаних знають, що це так.',
    },
    open: false,
    matched: false,
  },
  {
    id: 7,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-58.svg',
    title: '',
    action: true,
    actionData: {
      image: 'https://16days2022.rozirvykolo.org/static/icons/image-money.svg',
      color: 'teal',
      type: true,
      title: '58%',
      description: 'Якщо партнер чи партнерка змушують коханих працювати на двох роботах, привласнюють прибутки чи забороняють щось купувати — це економічне насильство. Від 45% до 58% людей правильно його визначають.',
    },
    open: false,
    matched: false,
  },
  {
    id: 8,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-70.svg',
    title: '',
    action: true,
    actionData: {
      color: 'light-gray',
      type: false,
      title: '70%',
      description: 'Коли партнер чи партнерка диктують своїм коханим, з ким їм бачитись і дружити, — це психологічне насильство. Від 50% до 70% опитаних у 30 громадах його розпізнають.',
    },
    open: false,
    matched: false,
  },
  {
    id: 9,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-90.svg',
    title: '',
    action: true,
    actionData: {
      color: 'orange',
      type: false,
      title: '90%',
      description: 'Якщо людину б’ють, замикають у кімнаті, погрожують силою або будь-яким іншим чином завдають болю — це фізичне насильство. І 70–90% опитаних вважають це неприпустимим у стосунках.',
    },
    open: false,
    matched: false,
  },
  {
    id: 10,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-belt-blue.svg',
    title: '',
    action: false,
    actionData: {
      image: 'https://16days2022.rozirvykolo.org/static/icons/image-belt.svg',
      color: 'cyan',
      type: true,
      title: '',
      description: `Побиття та інші тілесні ушкодження, порушення фізичного чи психічного здоров’я – це фізичне насильство. Це, наприклад, позбавлення їжі та відпочинку, примус до вживання наркотиків та алкоголю. Більше про те, як <a href="https://rozirvykolo.org/vidi-nasilstva/" target="_blank">розпізнати насильство</a> та де отримати допомогу, на сайті <a href="https://rozirvykolo.org/" target="_blank">“Розірви коло”</a>. `,
    },
    open: false,
    matched: false,
  },
  {
    id: 11,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-belt-orange.svg',
    title: '',
    action: true,
    actionData: {
      image: 'https://16days2022.rozirvykolo.org/static/icons/image-belt.svg',
      color: 'orange',
      type: true,
      title: '',
      description: `Побиття та інші тілесні ушкодження, порушення фізичного чи психічного здоров’я – це фізичне насильство. Це, наприклад, позбавлення їжі та відпочинку, примус до вживання наркотиків та алкоголю. Більше про те, як <a href="https://rozirvykolo.org/vidi-nasilstva/" target="_blank">розпізнати насильство</a> та де отримати допомогу, на сайті <a href="https://rozirvykolo.org/" target="_blank">“Розірви коло”</a>. `,
    },
    open: false,
    matched: false,
  },
  {
    id: 12,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-belt-yellow.svg',
    title: '',
    action: false,
    actionData: {
      image: 'https://16days2022.rozirvykolo.org/static/icons/image-belt.svg',
      color: 'yellow',
      type: true,
      title: '',
      description: `Побиття та інші тілесні ушкодження, порушення фізичного чи психічного здоров’я – це фізичне насильство. Це, наприклад, позбавлення їжі та відпочинку, примус до вживання наркотиків та алкоголю. Більше про те, як <a href="https://rozirvykolo.org/vidi-nasilstva/" target="_blank">розпізнати насильство</a> та де отримати допомогу, на сайті <a href="https://rozirvykolo.org/" target="_blank">“Розірви коло”</a>. `,
    },
    open: false,
    matched: false,
  },
  {
    id: 13,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-coctail-gray.svg',
    title: '',
    action: true,
    actionData: {
      image: 'https://16days2022.rozirvykolo.org/static/icons/image-stop.svg',
      color: 'light-gray',
      type: true,
      title: '',
      description: `Хлопець зайнявся сексом з дівчиною, із якою щойно познайомився у клубі. Вона не давала на це згоди та була під дією алкоголю. Він вчинив сексуальне насильство. Більше про те, як <a href="https://rozirvykolo.org/vidi-nasilstva/" target="_blank">розпізнати насильство</a> та де отримати допомогу, на сайті <a href="https://rozirvykolo.org/" target="_blank">“Розірви коло”</a>.`,
    },
    open: false,
    matched: false,
  },
  {
    id: 14,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-coctail-orange.svg',
    title: '',
    action: false,
    actionData: {
      image: 'https://16days2022.rozirvykolo.org/static/icons/image-stop.svg',
      color: 'light-orange',
      type: true,
      title: '',
      description: `Хлопець зайнявся сексом з дівчиною, із якою щойно познайомився у клубі. Вона не давала на це згоди та була під дією алкоголю. Він вчинив сексуальне насильство. Більше про те, як <a href="https://rozirvykolo.org/vidi-nasilstva/" target="_blank">розпізнати насильство</a> та де отримати допомогу, на сайті <a href="https://rozirvykolo.org/" target="_blank">“Розірви коло”</a>.`,
    },
    open: false,
    matched: false,
  },
  {
    id: 15,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-ear-gray.svg',
    title: '',
    action: false,
    actionData: {
      color: 'gray',
      type: false,
      title: '',
      description: 'Якщо почули крик і плач з сусідньої квартири, варто викликати поліцію, мобілізувати сусідів і спробувати разом допомогти людині, яку кривдять. Як діяти, якщо ви свідок насильства, на сайті <a href="https://rozirvykolo.org/ya-svidok-shho-robiti/" target="_blank">“Розірви коло”</a>.',
    },
    open: false,
    matched: false,
  },
  {
    id: 16,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-ear-red.svg',
    title: '',
    action: true,
    actionData: {
      color: 'red',
      type: false,
      title: '',
      description: 'Якщо почули крик і плач з сусідньої квартири, варто викликати поліцію, мобілізувати сусідів і спробувати разом допомогти людині, яку кривдять. Як діяти, якщо ви свідок насильства, на сайті <a href="https://rozirvykolo.org/ya-svidok-shho-robiti/" target="_blank">“Розірви коло”</a>.',
    },
    open: false,
    matched: false,
  },
  {
    id: 17,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-heart-blue.svg',
    title: '',
    action: false,
    open: false,
    matched: false,
  },
  {
    id: 18,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-heart-gray.svg',
    title: '',
    action: false,
    open: false,
    matched: false,
  },
  {
    id: 19,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-heart-orange.svg',
    title: '',
    action: false,
    open: false,
    matched: false,
  },
  {
    id: 20,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-heart-red.svg',
    title: '',
    action: false,
    open: false,
    matched: false,
  },
  {
    id: 21,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-heart-white.svg',
    title: '',
    action: false,
    open: false,
    matched: false,
  },
  {
    id: 22,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-heart-yellow.svg',
    title: '',
    action: false,
    open: false,
    matched: false,
  },
  {
    id: 23,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-home-blue.svg',
    title: '',
    action: false,
    actionData: {
      color: 'cyan',
      type: false,
      title: '',
      description: 'Куди звертатися, якщо людина розірвала коло насильства і потребує прихистку? На перший час особу направлять у кризову кімнату – це робить поліція, мобільна бригада чи соціальні служби. Перебувати у кімнаті можна до 10 днів, далі персонал кімнати може перенаправити до соціальних служб або медичних та юридичних установ. Жінкам та дітям гарантується безпека, надається цілодобова підтримка фахівців і допомога у пошуку альтернативного постійного житла.',
    },
    open: false,
    matched: false,
  },
  {
    id: 24,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-home-gray.svg',
    title: '',
    action: false,
    actionData: {
      color: 'light-gray',
      type: false,
      title: '',
      description: 'Куди звертатися, якщо людина розірвала коло насильства і потребує прихистку? На перший час особу направлять у кризову кімнату – це робить поліція, мобільна бригада чи соціальні служби. Перебувати у кімнаті можна до 10 днів, далі персонал кімнати може перенаправити до соціальних служб або медичних та юридичних установ. Жінкам та дітям гарантується безпека, надається цілодобова підтримка фахівців і допомога у пошуку альтернативного постійного житла.',
    },
    open: false,
    matched: false,
  },
  {
    id: 25,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-home-orange.svg',
    title: '',
    action: true,
    actionData: {
      color: 'light-orange',
      type: false,
      title: '',
      description: 'Куди звертатися, якщо людина розірвала коло насильства і потребує прихистку? На перший час особу направлять у кризову кімнату – це робить поліція, мобільна бригада чи соціальні служби. Перебувати у кімнаті можна до 10 днів, далі персонал кімнати може перенаправити до соціальних служб або медичних та юридичних установ. Жінкам та дітям гарантується безпека, надається цілодобова підтримка фахівців і допомога у пошуку альтернативного постійного житла.',
    },
    open: false,
    matched: false,
  },
  {
    id: 26,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-mobile-gray.svg',
    title: '',
    action: true,
    actionData: {
      color: 'light-gray',
      type: false,
      title: '',
      description: 'У випадку постійного контролю з боку кривдника, постраждалі можуть непомітно викликати поліцію через мобільний застосунок. Зовні він замаскований під звичайний додаток. Водночас він містить прихований інтерфейс з кнопкою SOS. Дзвонити не потрібно. Місце перебування визначається через геолокацію або зазначену під час реєстрації адресу. <br><br> Деталі по роботі застосунку розкажуть в особистих повідомленнях, писати треба на сторінку «Розірви коло» у <a href="https://www.facebook.com/rozirvykolo" target="_blank">Фейсбуці</a> або <a href="https://www.instagram.com/rozirvy_kolo/" target="_blank">Інстаграмі</a>',
    },
    open: false,
    matched: false,
  },
  {
    id: 27,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-mobile-orange.svg',
    title: '',
    action: false,
    actionData: {
      color: 'orange',
      type: false,
      title: '',
      description: 'У випадку постійного контролю з боку кривдника, постраждалі можуть непомітно викликати поліцію через мобільний застосунок. Зовні він замаскований під звичайний додаток. Водночас він містить прихований інтерфейс з кнопкою SOS. Дзвонити не потрібно. Місце перебування визначається через геолокацію або зазначену під час реєстрації адресу. <br><br> Деталі по роботі застосунку розкажуть в особистих повідомленнях, писати треба на сторінку «Розірви коло» у <a href="https://www.facebook.com/rozirvykolo" target="_blank">Фейсбуці</a> або <a href="https://www.instagram.com/rozirvy_kolo/" target="_blank">Інстаграмі</a>',
    },
    open: false,
    matched: false,
  },
  {
    id: 28,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-mouth-red.svg',
    title: '',
    action: true,
    actionData: {
      color: 'red',
      type: false,
      title: '',
      description: 'Словесні образи, погрози, переслідування та залякування, газлайтинг, шантаж, постійна критика – це психологічне насильство. Його мета – змусити відчувати емоційну невпевненість та нездатність захистити себе. Більше про те, як <a href="https://rozirvykolo.org/vidi-nasilstva/" target="_blank">розпізнати насильство</a> та де отримати допомогу, на сайті <a href="https://rozirvykolo.org/" target="_blank">“Розірви коло”</a>.',
    },
    open: false,
    matched: false,
  },
  {
    id: 29,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-mouth-yellow.svg',
    title: '',
    action: false,
    actionData: {
      color: 'yellow',
      type: false,
      title: '',
      description: 'Словесні образи, погрози, переслідування та залякування, газлайтинг, шантаж, постійна критика – це психологічне насильство. Його мета – змусити відчувати емоційну невпевненість та нездатність захистити себе. Більше про те, як <a href="https://rozirvykolo.org/vidi-nasilstva/" target="_blank">розпізнати насильство</a> та де отримати допомогу, на сайті <a href="https://rozirvykolo.org/" target="_blank">“Розірви коло”</a>.',
    },
    open: false,
    matched: false,
  },
  {
    id: 30,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-percent.svg',
    title: '',
    action: true,
    actionData: {
      color: 'orange',
      type: false,
      title: '',
      description: 'У випадку домашнього насильства, крім поліції, можна звернутись до соціальних служб і сервісів. Утім менш ніж 10% опитаних мають такі контакти. Дізнавайтеся, яку допомогу можна отримати у вашій громаді, на сайті <a href="https://rozirvykolo.org/" target="_blank">“Розірви коло”</a>.',
    },
    open: false,
    matched: false,
  },
  {
    id: 31,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-phone-orange.svg',
    title: '',
    action: false,
    actionData: {
      color: 'orange',
      type: false,
      title: '',
      description: 'Куди звертатися, якщо потрібна допомога у випадку гендерно зумовленого або домашнього насильства? Національна гаряча лінія: 116 123. Цілодобово, анонімно та конфіденційно. Надає інформаційні, юридичні, психологічні та соціальні консультації. Роботу гарячої лінії забезпечує ГО «Ла Страда-Україна» за підтримки UNFPA.',
    },
    open: false,
    matched: false,
  },
  {
    id: 32,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-phone-white.svg',
    title: '',
    action: true,
    actionData: {
      color: 'teal',
      type: false,
      title: '',
      description: 'Куди звертатися, якщо потрібна допомога у випадку гендерно зумовленого або домашнього насильства? Національна гаряча лінія: 116 123. Цілодобово, анонімно та конфіденційно. Надає інформаційні, юридичні, психологічні та соціальні консультації. Роботу гарячої лінії забезпечує ГО «Ла Страда-Україна» за підтримки UNFPA.',
    },
    open: false,
    matched: false,
  },
  {
    id: 33,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-police-orange.svg',
    title: '',
    action: true,
    actionData: {
      color: 'light-orange',
      type: false,
      title: '',
      description: 'Якщо на вулиці чоловік б’є жінку чи навпаки, варто викликати поліцію, об\'єднатися із перехожими та зупинити кривдника чи кривдницю. Як діяти, якщо ви свідок насильства, на сайті <a href="https://rozirvykolo.org/ya-svidok-shho-robiti/" target="_blank">“Розірви коло”</a>.',
    },
    open: false,
    matched: false,
  },
  {
    id: 34,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-police-red.svg',
    title: '',
    action: false,
    actionData: {
      color: 'red',
      type: false,
      title: '',
      description: 'Якщо на вулиці чоловік б’є жінку чи навпаки, варто викликати поліцію, об\'єднатися із перехожими та зупинити кривдника чи кривдницю. Як діяти, якщо ви свідок насильства, на сайті <a href="https://rozirvykolo.org/ya-svidok-shho-robiti/" target="_blank">“Розірви коло”</a>.',
    },
    open: false,
    matched: false,
  },
  {
    id: 35,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-police-white.svg',
    title: '',
    action: false,
    actionData: {
      color: 'teal',
      type: false,
      title: '',
      description: 'Якщо на вулиці чоловік б’є жінку чи навпаки, варто викликати поліцію, об\'єднатися із перехожими та зупинити кривдника чи кривдницю. Як діяти, якщо ви свідок насильства, на сайті <a href="https://rozirvykolo.org/ya-svidok-shho-robiti/" target="_blank">“Розірви коло”</a>.',
    },
    open: false,
    matched: false,
  },
  {
    id: 36,
    icon: 'https://16days2022.rozirvykolo.org/static/icons/icon-www.svg',
    title: '',
    action: true,
    actionData: {
      color: 'gray',
      type: false,
      title: '',
      description: 'Допомога є. Дізнавайся про доступні сервіси з протидії та запобігання гендерно зумовленому насильству на <a href="https://ukraine.unfpa.org/uk/node/9712" target="_blank">сайті</a> UNFPA та <a href="https://rozirvykolo.org" target="_blank">“Розірви коло”</a>.',
    },
    open: false,
    matched: false,
  },
];