<template>
  <div id="app" class="app">
    <div class="app-container">
      <div class="wrapper">
        <div class="wrapper-top">
<!--          <main-header/>-->
          <transition name="component-fade" mode="out-in">
            <router-view/>
          </transition>
        </div>
<!--        <div class="wrapper-bottom">-->
<!--          <main-footer/>-->
<!--        </div>-->
      </div>
    </div>
    <hidden/>
  </div>
</template>

<style src="@/assets/scss/main.scss" lang="scss"></style>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Hidden from '@/components/hidden/index.vue';
import MainHeader from '@/components/header/index.vue';
import MainFooter from '@/components/footer/index.vue';
import {isMobile, isIPad} from '@/helpers/variables/index'

export default {
  name: 'app',
  components: {
    Hidden,
    MainHeader,
    MainFooter
  },
  computed: {
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      variables: `setting/variables`
    })
  },
  created() {
  },
  mounted() {
    if (!isMobile.any()) {
      document.querySelector('body').classList.add('hover');
    }
    if (!isIPad.iOS()) {
      document.querySelector('body').classList.add('iPad');
    }
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
    }),
  }
}
</script>
