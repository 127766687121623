import {mapMutations} from "vuex";

//sections
import promoSection from '@/modules/home/section/promo/index.vue';

export default {
  name: "home",
  components:{
    promoSection,
  },
  data(){
    return{

    }
  },
  created() {
  },
  computed:{

  },

  methods:{
    ...mapMutations({
    })
  }
}