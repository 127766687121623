import {$http} from '@/utils/https'
import {
  CHANGE_LOADING_LIST,
  SET_LIST,
  SET_CONTACTS, GET_FILTERS_LOADING, SET_FILTERS, SET_LIST_LENGTH
} from '../mutation-types';
import {
  GET_CONTACTS, GET_FILTERS,
  GET_LIST,
} from '../action-types';


const state = {
  list: [],
  listLoading: false,
  contacts: null,
  filters: [],
  filterLoading: false
};

const getters = {
  contacts: state => state.contacts,
  list: state => state.list,
  listLoading: state => state.listLoading,
  filters: state => state.filters,
  filterLoading: state => state.filterLoading,
};

const actions = {
  [GET_LIST]: async ({commit}) => {
    commit(CHANGE_LOADING_LIST, true);
    try {
      const response = await $http.get(`v1/product?limit=${4}&main_page=true`);

      commit(SET_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_LIST, false);
    }
  },
  [GET_CONTACTS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/contact`);
      commit(SET_CONTACTS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_FILTERS]: async ({commit}) => {
    try {
      commit(GET_FILTERS_LOADING, true);
      const response = await $http.get(`v1/category?main_page=true`);
      commit(SET_FILTERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(GET_FILTERS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_LIST](state, list) {
    state.list = list;
  },
  [SET_CONTACTS](state, data) {
    state.contacts = data;
  },
  [CHANGE_LOADING_LIST](state, status) {
    state.listLoading = status;
  },
  [SET_LIST_LENGTH](state, count) {
    state.count = count;
  },
  [SET_FILTERS](state, list) {
    state.filters = list;
  },
  [GET_FILTERS_LOADING](state, status) {
    state.filterLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
