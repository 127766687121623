import {mapMutations} from "vuex";

//sections

export default {
  name: "tile",
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    pause: {
      type: Boolean,
      default: () => true
    },
    loose: {
      type: Boolean,
      default: () => false
    },
    win: {
      type: Boolean,
      default: () => false
    },
    color: {
      type: String,
      default: () => null
    },
  },
  components: {},
  data() {
    return {
      colors: [
        'gray',
        'light-gray',
        'teal',
        'light-orange',
        'orange',
        'red',
        'yellow',
        'cyan',
      ],
    }
  },
  created() {
  },
  computed: {
    getRandomInt() {
      let minimum = Math.ceil(0);
      let maximum = Math.floor(3);
      return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    },
    randomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)]
    }
  },
  methods: {
    ...mapMutations({}),
    showTile() {
      this.$emit('change-open', {
        data: this.data,
      });
    },
  }
}