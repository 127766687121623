export const prizes = [
  {
    id: 1,
    title: 'Худі',
    image: 'https://16days2022.rozirvykolo.org/static/prize/hoody-final.jpg',
    end: true,
  },
  {
    id: 2,
    title: 'Шопер',
    image: 'https://16days2022.rozirvykolo.org/static/prize/shoper-final.jpg',
    end: true,
  },
  {
    id: 3,
    title: 'Шарф',
    image: 'https://16days2022.rozirvykolo.org/static/prize/scarf-final.jpg',
    end: true,
  },

]