import {mapMutations} from "vuex";

//sections

export default {
  name: "about",
  components: {},
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    ...mapMutations({}),
    closeAbout(){
      this.$emit('change-about');
    }
  }
}