
export default {
  data () {
    return {
    }
  },
  props: {
    outline: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Submit'
    },
    progress: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'submit'
    },
    color: {
      type: String,
      default: 'yellow'
    }
  },
  methods: {
    changeEvent () {
      this.$emit('change-event')
    }
  }
}
