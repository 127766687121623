import {mapMutations} from "vuex";

//sections

export default {
  name: "looser",
  components: {},
  props: {
    data: {
      type: Object,
      default: ()=> {}
    },
  },
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    ...mapMutations({}),
    reload(){
      location.reload()
    }
  }
}