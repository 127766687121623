import {mapMutations} from "vuex";

//sections
import {prizes} from "@/modules/game/prizes";

export default {
  name: "info",
  components: {},
  props: {
    type: {
      type: Boolean,
      default: ()=> false
    },
  },
  data() {
    return {
      prizes: prizes,
      image: null,
      mobile: false,
    }
  },
  created() {
    this.mobile = window.innerWidth < 744
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    ...mapMutations({}),
    startGame(){
      this.$emit('change-start');
    },
    openImage(data){
      this.image = data
    },
  }
}