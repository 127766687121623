import {$http} from '@/utils/https'
import {
  GET_ITEM_LOADING,
  SET_ITEM,
  GET_LIST_LOADING,
  SET_LIST,
  CHANGE_SKIP,
  SET_LIST_LENGTH,
} from '../mutation-types';
import {
  GET_LIST,
  GET_ITEM
} from '../action-types';


const state = {
  list: [],
  currentItem: null,
  isLoading: false,
  isListLoading: false,
  skip: 0,
  limit: 12,
  listLength: 0,
};

const getters = {
  list: state => state.list,
  currentItem: state => state.currentItem,
  skip: state => state.skip,
  limit: state => state.limit,
  isLoading: state => state.isLoading,
  listLength: state => state.listLength,
  isListLoading: state => state.isListLoading,
};

const actions = {
  [GET_LIST]: async ({commit}) => {
    commit(GET_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/auth/me/history_orders`);

      commit(SET_LIST, response.data.data);
      // commit(SET_LIST_LENGTH, response.data.meta.count);
    } catch (e) {
      throw e;
    } finally {
      commit(GET_LIST_LOADING, false);
    }
  },
  [GET_ITEM]: async ({ commit }, payload) => {
    try {
      commit(GET_ITEM_LOADING, true);
      const response = await $http.get(`v1/auth/me/history_orders/${payload.id}`);
      commit(SET_ITEM, response.data.data);
    }catch (e) {
      throw e;
    }finally {
      commit(GET_ITEM_LOADING, false);
    }
  },
};

const mutations = {
  [SET_LIST](state, list) {
    state.list = list;
  },
  [SET_ITEM](state, list) {
    state.currentItem = list;
  },
  [GET_LIST_LOADING](state, status) {
    state.isListLoading = status;
  },
  [GET_ITEM_LOADING](state, status) {
    state.isLoading = status;
  },
  [CHANGE_SKIP](state, skip) {
    state.skip = skip;
  },
  [SET_LIST_LENGTH](state, count) {
    state.listLength = count;
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
