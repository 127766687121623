import {mapMutations} from "vuex";

//sections

export default {
  name: "action",
  components: {},
  props: {
    data: {
      type: Object,
      default: ()=> {}
    },
  },
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    ...mapMutations({
      setActionPause: 'system/CHANGE_ACTION_PAUSE'
    }),
  }
}