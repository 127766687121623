import {mapMutations} from "vuex";


export default {
  name: "success",
  components: {},
  data() {
    return {

    }
  },
  created() {
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    ...mapMutations({}),
  }
}