import {mapMutations} from "vuex";
import {tileData} from "@/modules/game/data";
//sections

export default {
  name: "winner",
  components: {},
  props: {
    data: {
      type: Object,
      default: ()=> {}
    },
  },
  data() {
    return {
      tileData: tileData
    }
  },
  created() {
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    ...mapMutations({}),
    getPrize(){
      this.$emit('change-prize');
    }
  }
}