import {mapActions, mapGetters, mapMutations} from "vuex";
import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email} from "vuelidate/lib/validators";
import DeliveryNovaPoshta from "@/modules/game/components/prize/components/delivery-nova-poshta/index.vue";
//sections
import {prizes} from "@/modules/game/prizes";

export default {
  name: "prize",
  mixins: [validationMixin],
  components: {
    DeliveryNovaPoshta,
  },
  data() {
    return {
      prizes: prizes,
      payload: {
        name: '',
        last_name: '',
        email: '',
        phone: '',
        city: '',
        department: '',
        prize: ''
      },
      terms: false,
      validationErrors: {},
      image: null,
    }
  },
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      last_name: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      email: {
        required,
        email,
        maxLength: maxLength(60),
      },
      phone: {
        required,
        minLength: minLength(19),
      },
      city: {
        required,
      },
      department: {
        required,
      },
      prize: {
        required,
      },
    },
  },

  created() {
    this.payload.prize = this.prizes[0].title
  },
  computed: {
    ...mapGetters({
      loading: 'order/isInvoiceLoading'
    }),
    nameError() {
      const error = []
      if (!this.$v.payload.name.$dirty) {
        return error
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 50))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    lastNameError() {
      const error = []
      if (!this.$v.payload.last_name.$dirty) {
        return error
      }
      if (!this.$v.payload.last_name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 50))
      }
      if (!this.$v.payload.last_name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    emailError() {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('errorEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 60))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    phoneError() {
      const error = []
      if (!this.$v.payload.phone.$dirty) {
        return error
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 19))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    cityError() {
      const error = []
      if (!this.$v.payload.city.$dirty) {
        return error
      }
      if (!this.$v.payload.city.required) {
        error.push(this.$t('errorRequired'))
      }
      if (this.validationErrors.city) {
        this.validationErrors.city.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    departmentError() {
      const error = []
      if (!this.$v.payload.department.$dirty) {
        return error
      }
      if (!this.$v.payload.department.required) {
        error.push(this.$t('errorRequired'))
      }
      if (this.validationErrors.department) {
        this.validationErrors.department.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    prizeError() {
      const error = []
      if (!this.$v.payload.prize.$dirty) {
        return error
      }
      if (!this.$v.payload.prize.required) {
        error.push(this.$t('errorRequired'))
      }
      if (this.validationErrors.prize) {
        this.validationErrors.prize.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      orderPrize: 'order/CREATE_INVOICE'
    }),
    closePrize(){
      this.$emit('change-prize');
    },
    insertData(data){
      this.payload.city = data.town
      this.payload.department = data.department
    },
    openImage(data){
      this.image = data
    },
    submitForm(){
      this.$v.$touch();
      if (!this.$v.payload.$invalid) {
        this.orderPrize(this.payload).then(()=>{
          this.$router.push({name: 'success'}).catch(()=>{console.log()})
        }).catch(error=>{
          if (error.status === 422) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for (const i in errors) {
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
          if (error.status === 418) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for (const i in errors) {
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        })
      }
    }
  }
}