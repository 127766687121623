import {mapMutations} from "vuex";
import {cloneDeep} from 'lodash'

//sections
import Controls from "@/modules/game/components/controls/index.vue";
import Playground from "@/modules/game/components/playground/index.vue";
import {tileData} from "@/modules/game/data";

export default {
  name: "game",
  components: {
    Controls,
    Playground
  },
  data() {
    return {
      pause: true,
      populate: [],
      loose: null,
      win: null,
      mobile: false,
      chunkedArray: [],
      currentChunk: 0,
    }
  },
  created() {
    this.mobile = window.innerWidth < 744

    this.chunkedArray = _.chunk(tileData, this.mobile ? 12 : 36)
    this.createTileChunk(0)
  },
  computed: {},

  methods: {
    ...mapMutations({}),

    createTileChunk(currentIndex){
      let doubleArray = [...this.chunkedArray[currentIndex],...cloneDeep(this.chunkedArray[currentIndex])].map((item,index)=>{
        return {...item, uniqId: index}
      })
      this.populate =  this.shuffle(doubleArray)
    },

    shuffle(array) {
      let currentIndex = array.length, temporaryValue, randomIndex;

      while (0 !== currentIndex) {

        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
    changeChunk(){
      this.currentChunk++
      this.createTileChunk(this.currentChunk)
    },
    changePause(pause){
      this.pause = pause.status
    },
    changeLoose(data){
      this.loose = data
    },
    changeWin(data){
      this.win = data
    },
  }
}