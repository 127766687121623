import {mapGetters, mapMutations} from 'vuex';
export default {
  name: 'hidden',
  components: {
  },
  computed: {
    ...mapGetters({
    })
  },
  methods: {
    ...mapMutations({
    })
  }
}
