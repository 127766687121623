// import {$http} from '@/utils/https'
// import {translation} from '@/helpers/translation'
// import {globalLoader} from '@/helpers/variables/index'
import {
  CHANGE_LOGIN_POPUP,
  CHANGE_REGISTRATION_POPUP,
  CHANGE_RESET_POPUP,
  CHANGE_RESTORE_POPUP,
} from '../mutation-types';

import {

} from '../action-types';

const state = {
  loginPopup:false,
  registrationPopup:false,
  resetPopup:false,
  restorePopup:false,
};

const getters = {
  loginPopup: state => state.loginPopup,
  registrationPopup: state => state.registrationPopup,
  resetPopup: state => state.resetPopup,
  restorePopup: state => state.restorePopup,
};

const actions = {
  // [GET_VARIABLES]: async ({commit}) => {
  //   try {
  //     const response = await $http.get('v1/variables');
  //     console.log(response.data.data);
  //     commit(SET_VARIABLES, response.data.data);
  //   } catch (e) {
  //     throw e;
  //   }
  // },
};

const mutations = {
  [CHANGE_LOGIN_POPUP](state, status) {
    state.loginPopup = status;
  },
  [CHANGE_REGISTRATION_POPUP](state, status) {
    state.registrationPopup = status;
  },
  [CHANGE_RESET_POPUP](state, status) {
    state.resetPopup = status;
  },
  [CHANGE_RESTORE_POPUP](state, status) {
    state.restorePopup = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
